import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="Senior Automation QA Engineer - Software Careers | MCRO"
    description={`Growing company has an immediate need for a QA Engineer with Automation experience. In this role, 
    you will work in a fast-paced environment on an Agile/Scrum team. You will test mobile and web applications that 
    the company's external users use each day, meaning your work will have a direct impact on the business. 
    You will need at least three years of QA experience along with Automation experience.`}
    metaContent="qa, automation, selenium, web driver, cucumber, webdriver.io, test cases, "
    id="automation-qa"
    jobTitle="Automation QA Lead"
    jobDescription={`Growing company has an immediate need for a QA Engineer with Automation
     experience. In this role, you will work in a fast-paced environment on an Agile/Scrum team.
      You will test mobile and web applications that the company's external users use each day,
       meaning your work will have a direct impact on the business. You will need at least three
       years of QA experience along with Automation experience. `}
    mailHref="mailto:jobs@mcro.tech?subject=Automation QA Job Application"
    responsibilities={[
      'Develop new automation tests or maintain an existing',
      'Sync up on a daily basis with a distributed team and respect the coding standards required',
      'Deliver in a controlled agile environment',
      'Responsible for automated tests',
      'Responsible for establishing the right testing process'
    ]}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj-Napoca Office',
      'Private healthcare insurance for employees'
    ]}
    techSkills={[
      'Great communication skills verbal and written English',
      'Strong experience with Selenium WebDriver',
      'Strong experience with Cucumber/Protractor/WebDriver.IO/SenchaTest',
      'Strong experience with SQL and no-sql queries',
      'Strong experience with finding and reporting bugs',
      'Experience in detecting system security problems and be aware of them',
      'Experience top writing test plans and test cases',
      'Ability to coordinate with the development team',
      'Ability to test upon acceptance criteria and report the bugs correctly',
      'Experience with Javascript and NodeJS is a plus *',
      'Experience in project management and analysis is a plus'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default CareersDetails;

export const query = graphql`
  query automationQACareerPage {
    file(relativePath: { eq: "careers-details-banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
